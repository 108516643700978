<template lang="pug">
div

</template>

<script>

export default {
  name: 'GoogleOAuthCallback',
  components: {
  },
  watch: {

  },
  computed: {
    isSignedIn () {
      return this.$store.getters.signedIn
    },
  },
  data() {
    return {
      authorizationCode: null,
    }
  },
  apollo: {

  },
  methods: {

  },
  mounted: function() {
    console.log(this.$route.query)
    this.authorizationCode = this.$route.query.code

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
