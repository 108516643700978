import Vue from 'vue'
import VueApollo from 'vue-apollo'

import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import store from '../store'
import { createConsumer}  from '@rails/actioncable'
import { ActionCableLink } from 'graphql-ruby-client'



const httpOptions = {
  // You should use an absolute URL here
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
  // !NOTE: the commented out code was to deal with CORS policy when frontend communicating to backend graphql on different domain.
  // The solve was to fix CORS policy on backend
  // !IMPORTANT: only set this if you using no-cors and can fix the Content-Type issue
  // credentials: 'include',
  fetchOptions: {
    // !IMPORTANT: settings this option will make Content-Type: text/plain instead of application/json
    // mode: 'no-cors'
  }
}


const getCableUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const host = process.env.VUE_APP_ACTIONCABLE_HOST
  const authToken = store.state.token
  return `${protocol}//${host}/cable?token=${authToken}`
};

const cable = createConsumer(getCableUrl())

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
  )
}

const cableOrHttpLink = ApolloLink.split(
  hasSubscriptionOperation,
  new ActionCableLink({cable}),
  createHttpLink(httpOptions)
)

// HTTP connection to the API
const httpLink = ApolloLink.split(
  operation => operation.getContext().hasUpload,
  createUploadLink(httpOptions),
  cableOrHttpLink
)

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const authToken = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_KEY)
  // using persistent Vuex Store to grab token.  This is currently using localstorage but should switch to cookies
  const authToken = store.state.token
  return {
    headers: {
      ...headers,
      authorization: authToken ? `Bearer ${authToken}` : '',
    },
  };
});

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  // We log every GraphQL errors
  if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
          console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          )

          // Vue.$toast.error(`Error: ${message}`, {

          // })
        })
  }

  // When a 401 error occur, we log-out the user.
  if (networkError && networkError.statusCode === 401) {
    store.commit('signOut')
    window.location.href = '/signout'
  }
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(onErrorLink).concat(httpLink),
  cache,
})

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network'
    }
  },
})

export default apolloProvider