import Vue from 'vue'

import App from './App.vue'
import router from './router'
import apolloProvider from './lib/ApolloProvider'
import store from './store'

import googleAPI from './lib/GoogleApi'
Vue.use(googleAPI.GAuth, googleAPI.clientConfig)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import Toast from './lib/Toast'
Vue.use(Toast.Toast, Toast.options)
Vue.mixin({
  methods: {
    errorToast: Toast.errorToast,
    errorsToast: Toast.errorsToast,
  },
})

import VModal from 'vue-js-modal'
Vue.use(VModal, { dialog: true, dynamicDefault:{draggable: false, resizeable: false, } })

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider: apolloProvider,
  store: store,
  render: h => h(App)
}).$mount('#app')
