// import the plugin
import GAuth from 'vue-google-oauth2'

// https://github.com/guruahn/vue-google-oauth2
// https://developers.google.com/identity/sign-in/web/reference#gapiauth2clientconfig


// see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
const scopes = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/userinfo.profile',
  'openid',
  'https://www.googleapis.com/auth/contacts.readonly',
  'https://www.googleapis.com/auth/contacts.other.readonly',
  // 'https://www.googleapis.com/auth/user.emails.read',
  'https://www.googleapis.com/auth/gmail.send',
]

// const discoveryDocs = [
//   "https://people.googleapis.com/$discovery/rest?version=v1"
// ]


const clientConfig = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: scopes.join(' '),
  // select_account or consent.  consent is for getting refresh token from auth code
  prompt: 'consent'
}


export default { GAuth, clientConfig }