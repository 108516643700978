const state = {
  user: {},
};

const getters = {
  user: state => {
    return state.user
  },
  signedIn: state => {
    return state.user.id != undefined
  }
};

const mutations = {
  signIn(state, user) {
    state.user = user
  },
  clearUser(state) {
    state.user = {}
  },
}

const actions = {
  signOut(context) {
    context.commit('clearUser')
    context.commit('clearToken')
    context.commit('clearProject')
    context.commit('clearParticipantSlug')
  },
  signIn(context, user) {
    context.commit('signIn', user)
    context.commit('clearProject')
    context.commit('clearParticipantSlug')
  },
}

export default { state, getters, mutations, actions }