<template lang="pug">
div.view_container(v-bind:class="{view_container_small: isSmallScreen, white_bg: isSignedIn}")
  Header(v-on:is-small-screen="setIsSmallScreen")
  Projects(v-show="isSignedIn" :isSmallScreen="isSmallScreen")
  div(v-show="!isSignedIn")
    div.title_block(v-bind:class="{title_block_smaller: isSmallScreen}")
      div.tagline(v-bind:class="{tagline_smaller: isSmallScreen}")
        span Make videos with friends
        br
        span.use_case  {{ useCase }}
      button.cta(v-on:click="signIn")
        span Make a video
      a.outline(href="/link/4786d9f8d309e45768ee4a1d25a7e95ca7ffbb19" v-if="false && !isSmallScreen")
        span Try it out and join a group video
      div.tap_container
        img.taphere(:src="tapHereSrc" v-on:click="handleTappedGif" v-show="showTapHere")
      div.howitworks FREE. No app to download. Make videos by sharing links.
      button.google_signin(v-on:click="googleSignIn" v-if="false") Sign In with Google
    div.video_overlay(v-on:click="toggleVideoMute")
    div.embed-container
      video#intro_video(src="https://wishwell-site-content.s3.amazonaws.com/home_example_lo.mp4" autoplay width="100%" height="100%" muted playsinline)
</template>

<script>
/* global gtag */

import Projects from '../components/Projects'
import Header from '../components/Header'
import config from '../appConfig'
import { SIGNIN_WITH_CODE_MUTATION } from '../graphql/mutations'

export default {
  name: 'Home',
  components: {
    Projects,
    Header,
  },
  computed: {
    showTapHere () {
      return (config.isSafari || config.isWindows || config.isOpera) && this.showTip
    },
    tapHereSrc () {
      return process.env.BASE_URL + "tap_here.gif"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    useCase () {
      return this.useCases[this.useCaseIndex]
    },
  },
  apollo: {

  },
  data () {
    return {
      useCases: [
        "wishing someone happy birthday!",
        "sending someone lots of love",
        "cheering up someone you care about",
        "honoring someone you love",
        "sharing what you admire in someone",
        "remembering great moments together",
        "sending holiday cheer",
      ],
      useCaseIndex: 0,
      timer: null,
      showTip: true,
      isSmallScreen: config.isSmallScreen(),
    }
  },
  methods: {
    handleTappedGif () {
      var video = document.getElementById("intro_video")
      video.muted = false
      this.showTip = false
    },
    toggleVideoMute: function() {
        var video = document.getElementById("intro_video")
        video.muted = !video.muted
    },
    signIn: function () {
      this.$router.push("/signup")
    },
    signinWithCode: function(authCode) {
      this.loading = true
      this.$apollo.mutate({
        mutation: SIGNIN_WITH_CODE_MUTATION,
        variables: {
          code: authCode,
        }
      }).then((data) => {
        console.log(data)
        const user = data.data.signInWithCode.user
        const token = data.data.signInWithCode.token
        const isNew = data.data.signInWithCode.isNew
        const errors = data.data.signInWithCode.errors

        if (token) {
          this.$store.commit('setToken',token)
        }
        if (user) {
          this.$store.dispatch('signIn',user)
        }
        if (token && user) {
          gtag('event','login')
          if (isNew) {
            this.$router.push({name:"CreateOrUpdateProject"})
          } else {
            this.$router.push({name:"Home"})
          }
        }

        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    googleSignIn: async function () {
      // const googleUser = await this.$gAuth.signIn()
      // console.log(googleUser)
      // window.user = googleUser
      const authCode = await this.$gAuth.getAuthCode()
      // console.log(authCode)
      this.signinWithCode(authCode)
      // The authCode that is being returned is the one-time code that you can send to your backend server, so that the server can exchange for its own access_token and refresh_token.
      // const userId = googleUser.getId()
      // const profile = googleUser.getBasicProfile()
      // const response = googleUser.getAuthResponse()
      // const token = response.access_token

    },
    cycleUseCases: function() {
      this.timer = setInterval(() => {
        if ((this.useCaseIndex+1) <= (this.useCases.length - 1)) {
          this.useCaseIndex = this.useCaseIndex+1
        } else {
          this.useCaseIndex = 0
        }
      }, 2500)
    },
    setIsSmallScreen: function(isSmall) {
      this.isSmallScreen = isSmall
    },
  },
  beforeUnmount: function () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }

    var video = document.getElementById("intro_video")
    video.pause()
  },
  mounted: function() {
    // window.vue = this
    var video = document.getElementById("intro_video")
    video.volume = 0.2
    video.muted = config.isSafari || config.isWindows || config.isOpera

    this.cycleUseCases()

    // this is to fix issue of video playing during projects list
    if (this.isSignedIn) video.pause()

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/home'})
    gtag('event','view_page')

    console.log(config.browser)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.view_container {
  background-color: #000000;
}

.white_bg {
  background-color: #ffffff;
}

.video_overlay {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:rgb(0,0,0,0.55);
}

.embed-container {
  --video--width: 1280;
  --video--height: 720;

  position: fixed;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index:100;
  top: 0px;
  left: 0px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.title_block {
  padding-top: 80px;
  position: relative;
  z-index: 1000;
  text-align: left;
  color: #ffffff;
  overflow: visible;
}

.title_block_smaller {
  padding-top: 34px;
}

.tagline {
  margin: 60px 0px 40px 0px;
  font-weight: 300;
  font-family: gibsonsemibold;
  font-size: 60px;
  color: ##ffffff;
}

.tagline_smaller {
  margin: 40px 0px 40px 0px;
  font-size: 48px;
}

.subtitle {
  padding: 0 20% 0 20%;
  font-size: 24px;
  font-weight: 300;
}

.use_case {
  text-decoration: underline;
  /* font-weight: bold; */
}
.tap_container {
  display: inline-block;
  position: relative;
  overflow: visible;
  height: 10px;;
}
.taphere {
  z-index: 1000;
  display: block;
  position: absolute;
  bottom: -100px;
  height: 200px;
  cursor: pointer;
}
/* button.google_signin:hover {
  background-color: #ffffff url("/btn_google_light_focus_ios.svg") no-repeat left;
  opacity: 1;
} */

button.cta {
  font-size: 28px;
}

.howitworks {
  font-family: gibsonregular;
  font-size: 16px;
  color:  rgba(255,255,255,0.55);
  margin: 12px 12px;
  width: 200px;
  text-align: center;
}

.outline {
  display: block;
  position: fixed;
  bottom: 5%;
  left: 3%;
  font-size: 20px;
  padding: 18px 36px;
  border: 2px solid rgba(255,255,255,0.8);
  border-radius: 36px;
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  text-decoration: none;
}
.outline:hover {
  text-decoration: underline;
}
</style>
