<template lang="pug">
div.projects_container
  v-dialog
  div.view_subtitle Your Videos
  div#my_projects
    div.project_container.create_project_button(v-on:click="create")
      img.add_icon(:src="addIconSrc")
      span Create new video
    input#input_field_to_copy(type="text")
    div.loading(v-show="loading")
      img(v-bind:src="loadingSrc")
    div.project_container(v-for="(project, index) in projects" v-bind:key="project.id")
      div.wrapper
        .project_buttons(v-if="!isSmallScreen")
          img(:src="recIconSrc" style="touch-action: manipulation" title="Record your video" v-on:click="goAddVideo(index)")
          img(v-if="project.numCompleted > 0" v-on:click="play(project.slug)" :src="playIconSrc" style="touch-action: manipulation" title="play videos")
          img(v-if="project.introVideoUrl" v-on:click="copyShareUrl(project.shareUrl)" :src="shareButtonSrc" style="touch-action: manipulation" title="Share this link on social media to allow anyone to add a video")
        div.project_info_container
          a.project_title(v-on:click="goUpdateProject(index)") {{ project.title ? project.title : "project "+(index+1) }}
          div.project_progress ({{ project.numCompleted }}/{{ project.numInvited }}) &nbsp;&nbsp; people added videos
          div.deadline
            span email will be sent {{ formatDeadline(project.deadline) }} &nbsp;&nbsp;
            span.completed(v-show="project.completed") video sent!
          div.delete_project
            a(v-on:click="confirmDelete(project.id, project.title)") remove
        .project_buttons_small(v-if="isSmallScreen")
          img(:src="recIconSrc" style="touch-action: manipulation" title="Record your video" v-on:click="goAddVideo(index)")
          img(v-if="project.numCompleted > 0" v-on:click="play(project.slug)" :src="playIconSrc" style="touch-action: manipulation" title="play videos")
          img(v-if="project.introVideoUrl" v-on:click="copyShareUrl(project.shareUrl)" :src="shareButtonSrc" style="touch-action: manipulation" title="Share this link on social media to allow anyone to add a video")
        div.clear
</template>

<script>

import { GET_PROJECTS_QUERY } from '../graphql/queries'
import { DELETE_PROJECT_MUTATION } from '../graphql/mutations'
import { clearSelection, formatDate } from '../lib/utils'

export default {
  name: 'Projects',
  props: {
    isSmallScreen: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  computed: {
    recIconSrc () {
      return process.env.BASE_URL + "video_record_button.svg"
    },
    loadingSrc () {
      return process.env.BASE_URL + "loading_dark.gif"
    },
    shareButtonSrc () {
      return process.env.BASE_URL + "share_button.svg"
    },
    addIconSrc () {
      return process.env.BASE_URL + "plus_icon_white.svg"
    },
    currentUser () {
      return this.$store.state.user.user
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    playIconSrc () {
      return process.env.BASE_URL + "play_project_button_icon.svg"
    },
  },
  watch: {
  },
  data () {
    return {
      projects: [],
      loading: false,
    }
  },
  apollo: {

  },
  methods: {
    confirmDelete: function(projectId, title) {
      this.$modal.show('dialog', {
        title: 'Remove this project?',
        text:title,
        buttons: [
          {
            title: 'Cancel',
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: 'Yes, remove',
            handler: () => {
              this.deleteProject(projectId)
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    deleteProject: function(projectId) {
      const vue = this
      this.$apollo.mutate({
        mutation: DELETE_PROJECT_MUTATION,
        variables: {
          projectId: projectId,
        }
      }).then((data) => {
        console.log(data)
        const success = data.data.deleteProject.success
        const errors = data.data.deleteProject.errors
        if (success) {
          vue.getProjects()
        } else {
          this.errorsToast(errors)
        }
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    formatDeadline: function(deadline) {
      return formatDate(deadline)
    },
    goAddVideo: function(project_index) {
      const project = this.projects[project_index]
      this.$store.commit('setProject', project)
      this.$router.push({name:"AddVideoContainer"})
    },
    copyShareUrl: function(url) {
      var copyText = document.getElementById("input_field_to_copy");

      copyText.value = url

      /* Select the text field */
      copyText.select()
      copyText.setSelectionRange(0, 99999) /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy")
      clearSelection()
      this.$toast.success("copied "+url+" to clipboard")
    },
    getProjects: function () {
      this.loading = true
      this.$apollo.query({
        query: GET_PROJECTS_QUERY,
        fetchPolicy: 'network-only',
      }).then((data) => {
        this.loading = false
        this.projects = data.data.projects
        if (this.projects.length <= 0) this.$router.push("/create")

        const errors = data.errors
        this.errorsToast(errors)
      }).catch((error) => {
        console.error(error)
        this.loading = false
        this.errorToast(error)

        if (error && /you are not signed in/i.test(error)) {
          this.$store.dispatch('signOut')
          this.$router.push({name:"SignOut"})
        }
      })
    },
    create: function() {
      this.$store.commit('clearProject')
      this.$router.push({name:"CreateOrUpdateProject"})
    },
    play: function(slug) {
      this.$router.push(
        {name:"PlayProject",
        params: {
          slug: slug
        }
      })
    },
    goUpdateProject: function(project_index) {
      const project = this.projects[project_index]
      this.$store.commit('setProject', project)
      this.$router.push({name:"UpdateProject"})
    },
  },
  mounted: function () {
    if (this.isSignedIn) { this.getProjects() }
    window.vue = this
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.project_container {
  margin: 24px 0px;
  background-color: #F6F7F6;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
}

#input_field_to_copy {
  position:absolute;
  z-index: 1;
  top: -100px;
  left: 0px;
  opacity: 0;
}

.create_project_button {
   background-color: RGBA(63, 39, 218, 1.00);
   color: #ffffff;
   font-family: gibsonsemibold;
   font-size: 24px;
   padding: 38px 38px;
   cursor: pointer;
}
.create_project_button:hover {
  background-color: #7737f8;
}

.add_icon {
  cursor:pointer;
  display: block;
  float:right;
}
.project_info_container {
  display: block;
  color: #35393C;
  float: none;
  width: auto;
  overflow: hidden;
  text-align: left;
}

.wrapper {
  position: relative;
  padding: 38px 38px;
}

a.project_title {
  display: block;
  color: #35393C;
  font-family: gibsonsemibold;
  font-size: 24px;
  margin-bottom: 6px;
}
.project_buttons {
  display: block;
  float: right;
}

.project_buttons_small {
  display: block;
  margin-top: 22px;
  text-align: left;
}

.project_buttons_small img {
  cursor: pointer;
  margin-right: 12px;
}

.project_buttons img {
  cursor: pointer;
  margin-left: 12px;
}

.project_container img:hover {
  opacity: 0.8;
}

.deadline {
  color: #9399AB;
  margin-top: 6px;
}
.deadline .completed {
  display: inline-block;
  background-color: #9399AB;
  color: #F5F6F5;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
}
.delete_project {
  margin-top: 4px;
}

.delete_project a {
  color: #a5aab6;
}
</style>
