<template lang="pug">
div.view_container
  Header
  br
  div.view_subtitle What is your video about?
  div.input_container
    input(v-model="title" type="text" placeholder="e.g. Sending birthday wishes to my dad")
  div.view_subtitle Who is this video for?
    div.project_type_selection
      Multiselect(
          v-model="selectedProjectType"
          :options="projectTypeOptions"
          label="name"
          track-by="value"
          :searchable="false"
          :close-on-select="true"
          :show-labels="false"
          placeholder="Select type"
          :allow-empty="false"
        )
    div.input_container(v-show="showRecipient")
      input(v-model="name" type="text" placeholder="Enter their name")
    div.input_container(v-show="showRecipient")
      input(v-model="email" type="text" placeholder="Enter their email")
  div.view_subtitle When should we send the video?
  DatePicker.center(v-model='deadline' color="indigo" :min-date='new Date()' is-dark)
  br
  br
  br
  button.cta(v-on:click="createOrUpdateProject" ) Next
  br
  br
  br

</template>

<script>
/* global gtag */

import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import {_} from 'vue-underscore';
import Multiselect from 'vue-multiselect'
import { CREATE_PROJECT_MUTATION } from '../graphql/mutations'
import { UPDATE_PROJECT_MUTATION } from '../graphql/mutations'

import Header from '../components/Header'

export default {
  name: 'CreateOrUpdateProject',
  props: {
  },
  components: {
    DatePicker,
    Header,
    Multiselect,
  },
  computed: {
    currentUser () {
      return this.$store.state.user.user
    },
    projectTypeOptions () {
      return [
        {name: "for someone else", value: "other"},
        {name: "for myself", value: "self"}
      ]
    },
    defaultProjectType () {
      var projectTypeDefault = {}
      if (!this.$store.state.currentProject?.projectType) return Object.assign(projectTypeDefault, this.projectTypeOptions[0])
      return Object.assign(projectTypeDefault, _.findWhere(this.projectTypeOptions, {value: this.$store.state.currentProject?.projectType }))
    },
  },
  watch: {
    selectedProjectType: function () {
      this.showRecipient = this.selectedProjectType.value == "other"
      if (this.selectedProjectType.value == "self") {
        this.name = this.currentUser.firstName
        this.email = this.currentUser.email
      } else {
        this.name = this.$store.state.currentProject?.recipientName
        this.email = this.$store.state.currentProject?.recipientEmail
      }
    },
  },
  data () {
    return {
      name: this.$store.state.currentProject?.recipientName || null,
      email: this.$store.state.currentProject?.recipientEmail || null,
      showRecipient: true,
      selectedProjectType:null,
      deadline: this.$store.state.currentProject?.deadline || null,
      project: this.$store.state.currentProject || null,
      title: this.$store.state.currentProject?.title || null,
    }
  },
  apollo: {
  },
  methods: {
    projectTypeToSelected: function(projectType) {
      const selected =  _.findWhere(this.projectTypeOptions, {value: projectType })
      if (selected) return selected
      return this.projectTypeOptions[0]
    },
    createProject: function() {
      this.$apollo.mutate({
        mutation: CREATE_PROJECT_MUTATION,
        variables: {
          input: {
            title: this.title,
            recipientName: this.name,
            recipientEmail: this.email,
            deadline: this.deadline,
            projectType: this.selectedProjectType.value,
          }
        }
      }).then((data) => {
        console.log(data)
        this.$store.commit('setProject',data.data.createProject)
        this.project = data.data.createProject
        this.$router.push({name:"AddAsk"})
      }).catch((error) => {
        console.error(error)
        this.errorToast(error)
      })
    },
    updateProject: function() {
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MUTATION,
        variables: {
          input: {
            id: this.project.id,
            title: this.title,
            recipientName: this.name,
            recipientEmail: this.email,
            deadline: this.deadline,
            projectType: this.selectedProjectType.value,
          }
        }
      }).then((data) => {
        // console.log(data)
        this.$store.commit('setProject',data.data.updateProject)
        this.project = data.data.updateProject
        this.$router.push({name:"AddAsk"})
      }).catch((error) => {
        console.error(error)
      })
    },
    createOrUpdateProject: function () {
      if (this.project && this.project.id) {
        this.updateProject()
      } else {
        this.createProject()
      }
    },
  },
  mounted: function () {
    window.vue = this
    this.selectedProjectType = this.defaultProjectType

    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path':  this.project?.id ? '/update_project' : '/create_project'})
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>

.project_type_selection {
  margin: 18px 0px;

}

.project_type_selection .multiselect, .project_type_selection .multiselect__input, .project_type_selection .multiselect__single {
  font-family: gibsonregular;
  font-size: 18px !important;
  color: #8990A1;
  background-color: #ffffff;
  min-height: 40px !important;
  cursor: pointer;
}

.project_type_selection .multiselect__input, .project_type_selection .multiselect__single  {
  padding: 16px 4%;

}

.project_type_selection .multiselect__select {
  top: 20px;
  right: 4px;
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.center {
  display: inline-block;
}
.section {
  margin-top: 20px;
  display: inline-block;
  text-align: center;
  padding: 24px 48px;
}

.title {
  font-weight: bold;
  font-size: 32px;
}

.view_subtitle {
  position: relative;
}



</style>

<style>