import Vue from 'vue'
import Vuex from 'vuex'
// import Cookies from 'js-cookie';
import createPersistedState from 'vuex-persistedstate';
import usersStore from './usersStore';

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      // getState: (key) => {
      //   return Cookies.getJSON(key);
      // },
      // setState: (key, state) => {
      //   Cookies.set(key, state, { expires: 3, secure: true }); // 3 days
      // },
      // storage: {
      //   getItem: (key) => Cookies.get(key),
      //   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
      //   setItem: (key, value) =>
      //     Cookies.set(key, value, { expires: 3, secure: true }),
      //   removeItem: (key) => Cookies.remove(key),
      // },
    }),
  ],
  modules: {
    user: usersStore
  },
  state: {
    token: null,
    currentProject: null,
    participantSlug: null,
  },
  getters: {
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken
    },
    clearToken(state) {
      state.token = null
    },
    setProject(state, newProject) {
      state.currentProject = newProject
    },
    clearProject(state) {
      state.currentProject = null
    },
    setParticipantSlug(state, newParticipantSlug) {
      state.participantSlug = newParticipantSlug
    },
    clearParticipantSlug(state) {
      state.participantSlug = null
    },
  },
  actions: {
    setToken(context, new_token) {
      context.commit('setToken', new_token)
    }
  }
})

export default store