<template lang="pug">
div#app
  router-view(:key="$route.fullPath")
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
        title: "WishWell - Make videos with friends",
        meta: [
            { name: 'description', content:  'WishWell - Make videos with friends and family wishing someone happy birthday, sending them holiday cheer, remembering great moments together, cheering up a friend ...'},
            { property: 'og:title', content: "WishWell - Make videos with friends"},
            { property: 'og:site_name', content: 'WishWell'},
            {property: 'og:type', content: 'website'},
            {name: 'robots', content: 'index,follow'}
        ]
    }
  },
  computed: {
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    currentUser () {
      return this.$store.state.user.user
    }
  },
  methods: {
    create: function () {
      this.$store.commit('clearProject')
      this.$router.push({name:"CreateOrUpdateProject"})
    },
  },
  mounted: function() {

  }
}
</script>

<style>
/* *********************************
  http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
********************************* */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, button, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

textarea:focus, input:focus{
    outline: none;
}

/* *********************************
  app global styles
********************************* */

@import './assets/gibson_fonts.css';

#index_loading {
  display: none;
}

body {
  margin: 0px 0px 0px 0px !important;
  background-color: #ffffff;
}

.view_container {
  width: 94%;
  height: 100vh;
  position: relative;
  padding: 3% 3%;
}

.view_container_small {
  padding: 5% 5%;
  width: 90%;
  height: 90%;
}

.wrapper {
  position: relative;
  text-align: center;
}

.clickable {
  cursor: pointer;
}

button {
  cursor: pointer;
  background-color: #f4f4f7;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  font-family: gibsonsemibold;
  font-size: 16px;
  color: #35393C;
  text-align: center;
  padding: 12px 36px;
}

button:hover {
  background-color: #e1e1e7;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#app {
  font-family: gibsonregular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  /* overflow: hidden; */
}

button.cta_secondary {
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  font-family: gibsonsemibold;
  font-size: 24px;
  padding: 20px 44px;
  background-color: #eeeef5;
}

button.cta_secondary:hover, button.cta_secondary:active, button.cta_secondary:focus-visible, button.cta_secondary:visited {
  background-color: #dbdbe6;
}

button.cta {
  background-image: radial-gradient(3% -5%, #FFBE7C 0%, #EA7490 33%, #C33589 68%, #7C149C 100%);
  background-color: #540BE7;
  box-shadow: 8px 16px 40px 0 rgba(0,0,0,0.13);
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  font-family: gibsonsemibold;
  font-size: 24px;
  color: #FFFFFF;
  padding: 20px 44px;
}

.google_logo {
  background: url("/google_logo_transparent.svg") no-repeat left 18px bottom 50%;
  background-color: #f4f4f7;
}


button.google_signin {
  position: relative;
  z-index: 1000;
  background: #540BE7 url("/google_logo_transparent.svg") no-repeat left 24px bottom 50%;
  height: 60px;
  padding: 12px 68px;
  border: none;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 28px;
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  box-shadow: 0 2px 60px rgba(0,0,0,0.15);
}
button.google_signin:hover {
  background: #7737f8 url("/google_logo_transparent.svg") no-repeat left 24px bottom 50%;
}

button.cta:hover, button.cta:active, button.cta:focus-visible, button.cta:visited {
  background-color: #7737f8;
  opacity: 1.0;
}

button.small_screen {
  display: block;
  margin: 20px 0px;
}

.input_container {
  margin: 18px 0px;

  background-color: #F6F6F7;
  border-radius: 18px;
  /* border: 1px solid #D8DCE8; */
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
}

.input_container input  {
  background-color: #F6F6F7;
  text-align: left;
  padding: 16px 4%;
  margin: 0;
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: #2D333D;
  overflow-y: hidden;
  overflow-x: scroll;
  width: 92%;
  font-family: gibsonregular;
  border-radius: 18px;
  /* border: 1px solid #D8DCE8; */
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
}

  .input_container input:-webkit-autofill,
  .input_container input:-webkit-autofill:hover,
  .input_container input:-webkit-autofill:active,
  .input_container input:-webkit-autofill:focus
   {
  background-color: #F6F6F7 !important;
  -webkit-box-shadow: 0 0 0 60px #F6F6F7 inset !important;
  border: none !important;
}

/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

a {
  cursor: pointer;
  color: #ffffff;
}

a:hover {
  text-decoration: underline;
}

.loading  {
  text-align: center;
}

.loading img {
  display: inline-block;
  width: 40px;
  height: 40px;
}

.view_title {
  font-family: gibsonsemibold;
  font-size: 40px;
  color: #151516;
  margin: 40px 0 28px 0;
}

.view_subtitle {
  font-family: gibsonsemibold;
  font-size: 24px;
  color: #151516;
  margin: 40px 0 28px 0;
}

.clear {
  clear: both;
}

.video_controls {
  position: fixed;
  z-index: 600;
  bottom: 8%;
  left: 0px;
  width: 100%;
}

.video_controls .start, .video_controls .stop, .video_controls .next {
  display: inline-block;
  cursor: pointer;
  min-width: 80px;
  height: 80px;
  margin: 0px 8px;
}


.select_input_device .multiselect,
.select_input_device .multiselect__tags,
.select_input_device .multiselect__content,
.select_input_device .multiselect__placeholder,
.select_input_device .multiselect__single {
  background: none;
  color: #ffffff !important;
  border: none;
}

.select_input_device .multiselect__content-wrapper {
  background-color: rgba(0,0,0,0.45);
}

.select_input_device .multiselect__option--selected {
  background-color: rgba(255,255,255,0.7);
  color: #ffffff !important;
}

.hideaway {
  position: fixed;
  top: 110%;
  left: 110%;
  opacity: 0;
}
</style>
