import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import SignOut from '../views/SignOut.vue'
import CreateOrUpdateProject from '../views/CreateOrUpdateProject.vue'
import GoogleOAuthCallback from '../views/GoogleOAuthCallback.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/oauthcallback',
    name: 'GoogleOAuthCallback',
    component: GoogleOAuthCallback
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signin',
    name: 'Login',
    component: Login
  },
  {
    path: '/signin/:next',
    name: 'LoginWithNext',
    component: Login,
    props: true
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/signout',
    name: 'SignOut',
    component: SignOut
  },
  {
    path: '/reset_password/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/done',
    name: 'Done',
    component: () => import('../views/Done.vue')
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import('../views/Thanks.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/create',
    name: 'CreateOrUpdateProject',
    component: CreateOrUpdateProject
  },
  {
    path: '/update',
    name: 'UpdateProject',
    component: CreateOrUpdateProject
  },
  {
    path: '/add_ask',
    name: 'AddAsk',
    component: () => import('../views/AddAsk.vue')
  },
  {
    path: '/add_intro',
    name: 'AddIntroVideoContainer',
    component: () => import('../views/AddIntroVideoContainer.vue')
  },
  {
    path: '/add_video/:slug',
    name: 'AddParticipantVideoContainer',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AddParticipantVideoContainer.vue')
  },
  {
    path: '/invited/:slug',
    name: 'InvitedParticipant',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InvitedParticipant.vue')
  },
  {
    path: '/add_video',
    name: 'AddVideoContainer',
    component: () => import('../views/AddVideoContainer.vue')
  },
  {
    path: '/play/:slug',
    name: 'PlayProject',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlayProject.vue')
  },
  {
    path: '/invite',
    name: 'InvitePeople',
    component: () => import('../views/InvitePeople.vue')
  },
  {
    path: '/invite/:project_id',
    name: 'InvitePeopleWithSlug',
    props: true,
    component: () => import('../views/InvitePeople.vue')
  },
  {
    path: '/join/:slug',
    name: 'JoinProjectAsParticipant',
    component: () => import('../views/JoinProjectAsParticipant.vue')
  },
  {
    path: '/link/:slug',
    name: 'PlayAndJoinProject',
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PlayAndJoinProject.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
