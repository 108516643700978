<template lang="pug">
div#header
  a#logo_container(href="/")
    img.logo(:src="logo")
    img.logotype(v-show="false" :src="logotype")

  span(v-show="!isSignedIn && showSignIn" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="next('Login')") Sign In
  span(v-show="isSignedIn && showSignIn" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="signOut")
      span.signed_in_email(v-show="!isSmallScreen") {{ currentUser.email }}&nbsp;
      span &nbsp;sign out
  span(v-show="!isSignedIn && showAbout" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="next('About')") About
  span(v-show="!isSignedIn && showPrivacy" v-bind:class="{ dark: useDark, smallScreen:isSmallScreen}")
    button(v-on:click="next('Privacy')") Privacy
  div.clear
</template>

<script>
import config from '../appConfig'

export default {
  name: 'Header',
  props: {
  },
  components: {
  },
  computed: {
    logo () {
      return process.env.BASE_URL + "logo.svg"
    },
    logotype () {
      return this.useDark ? process.env.BASE_URL + "logotype.svg" : process.env.BASE_URL + "logotype white.svg"
    },
    showPrivacy () {
      return this.$router.currentRoute.name != "Privacy"
    },
    showAbout () {
      return this.$router.currentRoute.name != "About"
    },
    showSignIn () {
      return this.$router.currentRoute.name != "Login" &&
            this.$router.currentRoute.name != "ResetPassword" &&
            this.$router.currentRoute.name != "PlayProject" &&
            this.$router.currentRoute.name != "InvitedParticipant" &&
            this.$router.currentRoute.name != "JoinProjectAsParticipant"
    },
    useDark () {
      return (this.$router.currentRoute.name=="Home" && this.isSignedIn) ||
              this.$router.currentRoute.name =="CreateOrUpdateProject"  ||
              this.$router.currentRoute.name == "UpdateProject" ||
              this.$router.currentRoute.name == "AddAsk" ||
              this.$router.currentRoute.name == "Done" ||
              this.$router.currentRoute.name == "SignUp" ||
              this.$router.currentRoute.name == "Login" ||
              this.$router.currentRoute.name == "LoginWithNext" ||
              this.$router.currentRoute.name == "InvitePeople" ||
              this.$router.currentRoute.name == "InvitePeopleWithSlug" ||
              this.$router.currentRoute.name == "About" ||
              this.$router.currentRoute.name == "Privacy" ||
              this.$router.currentRoute.name == "Terms" ||
              this.$router.currentRoute.name == "Thanks"
    },
    isSignedIn () {
      return this.$store.getters.signedIn
    },
    currentUser () {
      return this.$store.state.user.user
    },
  },
  data () {
    return {
      isSmallScreen: config.isSmallScreen(),
    }
  },
  methods: {
    onResize() {
      // console.log('window has been resized', event)
      this.isSmallScreen = config.isSmallScreen()
      this.$emit('is-small-screen', this.isSmallScreen)
    },
    next: function (nextName) {
      this.$router.push({name:nextName})
    },
    signOut: function() {
      this.$store.commit('clearUser')
      this.$store.commit('clearToken')
      this.$store.commit('clearProject')
      this.$router.push({name:"Home"})
    }
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  mounted: function () {
    window.addEventListener('resize', this.onResize)

    this.$emit('is_small_screen', config.isSmallScreen())

    // window.vue = this
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#logo_container {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 22px;
}

#header {
  width: 100%;
  position: relative;
  z-index: 2000;
}

.logo, .logotype {
  display: inline-block;
}

.logo {
  width: 48px;
  height: 20px;
}
.logotype {
  margin-left: 10px;
  opacity: 0.7;
}

#header button  {
  display: inline-block;
  float: right;
  padding: 8px 26px;
  color: RGBA(255, 255, 255, .8);
  background-color: RGBA(28, 32, 54, .18);
  margin-left: 12px;
}

#header .smallScreen button {
  margin-bottom: 12px;
}

#header .dark button {
  background-color: #F6F7F6;
  color: #35393C;
}

.signed_in_email {
  font-family: gibsonregular;
  font-size: 16px;
  color: #A2A9AE;
  text-align: center;
}


</style>
