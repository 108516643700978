<template lang="pug">
div#signout

</template>

<script>
/* global gtag */

export default {
  name: 'SignOut',
  components: {

  },
  apollo: {

  },
  methods: {
    signOut: function() {
      this.$store.dispatch('signOut')
      this.$router.push({name:"Home"})
    },
  },
  mounted: function() {
    this.signOut()
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/signout'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.home {
  width: 100vw;
  height: 100vh;
  position: relative;
}

.video_overlay {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color:rgb(0,0,0,0.65);
}
.embed-container {
  --video--width: 1280;
  --video--height: 720;

  position: relative;
  padding-bottom: calc(var(--video--height) / var(--video--width) * 100%);
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index:100;
  top: 0px;
  left: 0px;
}

.embed-container iframe,
.embed-container object,
.embed-container embed,
.embed-container video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
