<template lang="pug">
div.signin
  SignIn
</template>

<script>
/* global gtag */

// @ is an alias to /src
import SignIn from '@/components/SignIn.vue'

export default {
  name: 'Login',
  components: {
    SignIn
  },
  methods: {
  },
  mounted: function() {
    gtag('config', process.env.VUE_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID, {'page_path': '/signin'})
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.signin {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: white;
}
</style>
